import {
  Button,
  Form,
  Modal,
  Skeleton,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { selectedApp, useStore } from "../../../store";
import {
  isFileChangeError,
  toBase64,
  toText,
  updateLinuxPgpKey,
  updateMacCert,
} from "../../../~reusables/actions";
import { callFirebaseFunction } from "../../../~reusables/firebase";
import useForm from "../../../~reusables/hooks/useForm";
import { CertTabs, IHSMCertificate } from "../../../~reusables/types";
import {
  certificateValidation,
  linuxPgpValidation,
} from "../../../~reusables/util/validationRules";
import { FormInput, FormItem } from "../../atoms/FormUtils";
import { Flex } from "../../atoms/Primitives";
import { Heading } from "../../atoms/Heading";
import { Box } from "../../atoms/Primitives";
import ReactTimeAgo from "react-time-ago";
import certPlaceholder from "../../../~reusables/images/certificate.png";
import {
  CertificateChangeWarning,
  TablessCertPane,
  genFormEvent,
} from "./SharedCertUtils";
import { MacTarget } from "@todesktop/shared";

const { Text } = Typography;

interface ReplacePGPCertificateModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave?: (data: { password: string; ascString: string }) => void;
}

const ReplacePGPCertificateModal: React.FC<ReplacePGPCertificateModalProps> = ({
  isOpen,
  onClose,
  onSave,
}) => {
  const [showInstructions, setShowInstructions] = useState(false);
  const app = useStore(selectedApp);
  const form = useForm(
    { password: "", ascString: "" },
    async (data) => {
      try {
        await updateLinuxPgpKey(app, data.ascString, data.password);
        if (onSave) onSave(data);
      } catch (err) {
        message.error(err.message);
      }
      onClose();
    },
    linuxPgpValidation
  );

  return (
    <Modal
      title={
        <>
          Add New PGP Key
          <br />{" "}
          <Button
            style={{ padding: "0", fontSize: "12px" }}
            type="link"
            onClick={() => setShowInstructions(!showInstructions)}
          >
            {showInstructions ? "↓ Hide" : "→ Show"} instructions for quickly
            creating a PGP key
          </Button>
        </>
      }
      open={isOpen}
      onCancel={onClose}
      footer={
        <>
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={form.isSubmitting}
            onClick={form.handleSubmit}
          >
            Save
          </Button>
        </>
      }
    >
      {showInstructions && (
        <Text>
          <pre style={{ marginTop: 0, fontSize: "12px" }}>
            gpg --quick-generate-key &quot;Company Name
            &lt;company@email.com&gt;&quot; default default never
            <br />
            <br />
            gpg --export-secret-keys --armor company@email.com &gt;
            private-key.asc
          </pre>
        </Text>
      )}
      <Form
        autoComplete="off"
        layout="vertical"
        size="small"
        onFinish={form.handleSubmit}
      >
        <Flex css={{ flexDirection: "column", gap: "10px" }}>
          <FormItem label="" error={form.errors.ascString}>
            <Upload
              onChange={({ file }) => {
                if (!file || isFileChangeError(file)) return;

                toText((file as unknown) as Blob)
                  .then((text) => {
                    form.handleChange(genFormEvent("ascString", text));
                  })
                  .catch((err) => message.error(err.message, 3));
              }}
              accept=".asc"
              maxCount={1}
              beforeUpload={() => false}
            >
              <Button>Select Private Key File (.asc)</Button>
            </Upload>
          </FormItem>
          <div hidden={form.values.ascString === ""}>
            <FormInput
              error={form.errors.password}
              label="Private Key Password (optional)"
              name="password"
              onChange={form.handleChange}
              value={form.values.password}
              disabled={form.isSubmitting}
              password
            />
          </div>
        </Flex>
      </Form>
    </Modal>
  );
};

export interface CertificateProps {
  target: Exclude<MacTarget, "mac">; // mac is handled in MacCertificate.tsx
  label: string;
  hiddenIfNotInUse?: boolean;
  after?: React.ReactNode;
  hasFinishedLoading?: (certExists: boolean) => void;
}

export interface LinuxPGPCertificateProps {
  metadata: any;
  onReplace?: () => void;
}

export const LinuxPGPCertificate: React.FC<LinuxPGPCertificateProps> = ({
  metadata,
  onReplace,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {!metadata?.userIDs ? (
        <>
          <Text>
            We will use your PGP key to sign release metadata in Linux APT
            (Debian) repositories.
            <br />
            If you do not provide your own key, then your release metadata will
            be signed with ToDesktop&apos;s PGP key.
          </Text>
          <Flex
            css={{ alignItems: "center", marginTop: "10px", flexWrap: "wrap" }}
          >
            <Button onClick={() => setIsModalOpen(true)}>Add PGP Key</Button>
          </Flex>
        </>
      ) : (
        <Flex css={{ flexDirection: "column", gap: "16px", width: "100%" }}>
          <Flex css={{ flexDirection: "row", gap: "8px", width: "100%" }}>
            <Box maxWidth={120}>
              <img width="100%" src={certPlaceholder} />
            </Box>
            <Flex css={{ flexDirection: "column", gap: "8px", width: "100%" }}>
              <Heading style={{ marginBottom: 10 }} variant="h5" as="h5">
                {metadata.userIDs[0]}
              </Heading>

              {metadata.expires && (
                <div>
                  {new Date(metadata.expires) > new Date()
                    ? "Expires"
                    : "Expired"}{" "}
                  <b>
                    {metadata.expires && (
                      <ReactTimeAgo date={new Date(metadata.expires)} />
                    )}
                  </b>
                  .
                </div>
              )}
              {metadata.created && (
                <div>
                  Created:{" "}
                  <b>
                    {metadata.created && (
                      <ReactTimeAgo date={new Date(metadata.created)} />
                    )}
                  </b>
                  .
                </div>
              )}
              {metadata.keyID && (
                <div>
                  Key ID: <b>{metadata.keyID}</b>.
                </div>
              )}
              <Flex
                css={{ alignItems: "center", gap: "8px", flexWrap: "wrap" }}
              >
                <Button onClick={() => setIsModalOpen(true)}>
                  Replace Certificate
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
      <ReplacePGPCertificateModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={onReplace}
      />
    </>
  );
};

export const Certificate: React.FC<CertificateProps> = ({
  target,
  label,
  hiddenIfNotInUse = false,
  after,
  hasFinishedLoading,
}) => {
  const app = useStore(selectedApp);
  const { id, macHsmCertNames } = app;

  const [state, setState] = useState<{
    hsmCertDetails: IHSMCertificate | null;
    loading: boolean;
    error: string;
    tab: CertTabs;
  }>({
    hsmCertDetails: null,
    loading: true,
    error: "",
    tab: CertTabs.AddCert,
  });

  const hsmCertName = macHsmCertNames?.[target];
  useEffect(() => {
    // because nothing is being stored in firestore, we need to optimistically make this request for data.
    // For that reason, we won't display an error if it fails to find something.
    getHSMCert({ type: target, appId: id, hsmCertName })
      .then((res) => {
        const hsmCertDetails = res.data;
        setState((prev) => ({
          ...prev,
          hsmCertDetails,
          loading: false,
          tab: CertTabs.ViewCert,
        }));
        hasFinishedLoading?.(!!hsmCertDetails);
      })
      .catch((err) => {
        console.warn(err);
        setState((prev) => ({
          ...prev,
          hsmCertDetails: null,
          loading: false,
          tab: CertTabs.AddCert,
        }));
        hasFinishedLoading?.(false);
      });
  }, [id, target, hsmCertName]);

  const form = useForm(
    { password: "", pfxBase64: "" },
    async (data) => {
      const { pfxBase64, password } = data;

      try {
        await updateMacCert(app, password, pfxBase64, target);
        message.success("Certificate saved");
        setState((prev) => ({ ...prev, tab: CertTabs.ViewCert }));
      } catch (err) {
        console.warn(err);
        setState((prev) => ({ ...prev, error: err.message }));
      }
    },
    certificateValidation
  );

  useEffect(() => {
    if (form.formError) message.error(form.formError);
  }, [form.formError]);

  if (!hiddenIfNotInUse && state.loading) return <Skeleton />;

  return (
    <div hidden={hiddenIfNotInUse && (!state.hsmCertDetails || state.loading)}>
      <Flex css={{ flexDirection: "column", gap: "8px" }}>
        <Text style={{ fontWeight: 500 }}>{label}</Text>
        <TablessCertPane
          tabUtils={{
            certTab: state.tab,
            setCertTab: (tab) => {
              setState((prev) => ({ ...prev, tab }));
            },
            isCertInUse: state.hsmCertDetails ? true : false,
          }}
          viewComponentProps={{
            cert: state.hsmCertDetails || { name: "" },
            target,
          }}
          addComponent={
            <Form layout="vertical" size="small" onFinish={form.handleSubmit}>
              <Flex css={{ flexDirection: "column", gap: "10px" }}>
                {state.hsmCertDetails && <CertificateChangeWarning />}
                <FormItem label="" error={state.error || form.errors.pfxBase64}>
                  <Upload
                    onChange={({ file }) => {
                      if (!file || isFileChangeError(file)) return;

                      toBase64((file as unknown) as Blob)
                        .then((base64) => {
                          form.handleChange(genFormEvent("pfxBase64", base64));
                        })
                        .catch((err) => message.error(err.message, 3));
                    }}
                    accept=".p12"
                    maxCount={1}
                    beforeUpload={() => false}
                  >
                    <Button>Select File</Button>
                  </Upload>
                </FormItem>
                <div hidden={form.values.pfxBase64 === ""}>
                  <FormInput
                    error={form.errors.password}
                    label="Certificate Password"
                    name="password"
                    onChange={form.handleChange}
                    value={form.values.password}
                    disabled={form.isSubmitting}
                    password
                  />
                </div>
              </Flex>

              <Flex justifyContent="end">
                <Button
                  htmlType="submit"
                  loading={form.isSubmitting}
                  type="primary"
                >
                  Save
                </Button>
              </Flex>
            </Form>
          }
        />
      </Flex>
      {after}
    </div>
  );
};

const getHSMCert = callFirebaseFunction("getHSMCert");
