/* eslint-disable react/display-name */
import { ArrowLeftOutlined } from "@ant-design/icons";
import { PlatformName, ReleaseRedirectionRule } from "@todesktop/shared";
import {
  Alert,
  Anchor,
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  SelectProps,
  Space,
  Typography,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import semver from "semver";
import { useDebouncedCallback } from "use-debounce";
import { TypedLink } from "../../../components/Router";
import { BuildReleaseTag } from "../../../components/atoms/BuildTag";
import { Box, Flex } from "../../../components/atoms/Primitives";
import {
  ReleaseBuildButton,
  ReleaseBuildButtonProps,
} from "../../../components/molecules/ReleaseBuildButton";
import { useStore } from "../../../store";
import { selectedReleasedBuild } from "../../../~reusables/actions/builds";
import { useTheme } from "../../../~reusables/contexts/ThemeContext";
import { getSortedReleasedBuilds } from "../../../~reusables/util";
import { determineReleaseEligibility } from "../../../~reusables/util/determineReleaseEligibility";
import { isBuildSupportsSmokeTests } from "../../../~reusables/util/smokeTest";
import { ipAddressesValidation } from "../../../~reusables/util/validationRules";
import { BasicValidationSection } from "./BasicValidationSection";
import { BuildArtifactsSection } from "./BuildArtifactsSection";
import { BuildLoggingSection } from "./BuildLoggingSection";
import {
  ReleaseContext,
  ReleaseSectionEnum,
  ReleaseSectionState,
  Status,
  useReleaseContext,
  useReleaseStore,
} from "./ReleaseContext";
import {
  SectionLink,
  StatusCheckIcon,
  StatusIcon,
  StatusIconFilled,
} from "./ReleaseSection";
import { ReleaseTutorialModal } from "./ReleaseTutorial";
import { SmokeTestingSection } from "./SmokeTestingSection";
import { StaticAnalysisSection } from "./StaticAnalysisSection";
import { StatusCheckContent, useContent } from "./useContent";
import { Link } from "react-router-dom";
import { getSecurityTokenDetails } from "../../../~reusables/firebase/webauthnUtils";
import confirm from "antd/lib/modal/confirm";
import { goToManageSecurityToken } from "../../../~reusables/actions";
import { stage } from "../../../~reusables/firebase/config";

const { Title, Text } = Typography;

export const ReleaseRoute: React.FC<RouteComponentProps<{
  buildId: string;
  id: string;
}>> = (routeProps) => {
  const { colors } = useTheme();
  const history = useHistory();

  const { id, buildId } = routeProps.match.params;
  const popupContainer = useRef<HTMLDivElement>(null);
  const releaseContext = useReleaseStore(routeProps);
  const build = releaseContext.props.build;

  const { isPreparable } = determineReleaseEligibility(
    build,
    useStore(selectedReleasedBuild)
  );

  if (!build) return null;
  if (!isPreparable) {
    return (
      <Flex
        css={{
          width: "100vw",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Text>This build cannot be released</Text>
        <Button
          type="primary"
          onClick={() => {
            const { id, buildId } = routeProps.match.params;
            history.push(`/apps/${id}/builds/${buildId}`);
          }}
        >
          Go back
        </Button>
      </Flex>
    );
  }

  return (
    <ReleaseContext.Provider value={releaseContext}>
      <Row style={{ background: "white", minHeight: "100vh" }}>
        <Col span={6}>
          <Box
            css={{
              ".ant-anchor-link": {
                borderLeft: "2px solid transparent",
                paddingLeft: "16px",
                paddingRight: "4px", // equalizing the space creates a bit too much space
                borderTopRightRadius: "4px",
                borderBottomRightRadius: "4px",
              },
              ".ant-anchor-ink-ball-visible": { display: "none !important" },
              ".ant-anchor-link-active": {
                background: "#F5F5F5",
                borderLeft: `2px solid ${colors.title}`,
              },
            }}
          >
            <Anchor offsetTop={24}>
              <Flex
                css={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <TypedLink
                  to="/apps/:id/builds/:buildId"
                  params={{ id, buildId }}
                >
                  <Button type="text" icon={<ArrowLeftOutlined />}>
                    Back
                  </Button>
                </TypedLink>
                <BuildReleaseTag build={build} />
              </Flex>
              <Flex
                ref={popupContainer}
                css={{
                  padding: "8px 0 4px 16px",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                <Text>
                  Review/compare your build against previous releases.
                </Text>
                <SelectRelease
                  style={{ marginRight: "8px" }}
                  listHeight={216}
                  getPopupContainer={() => {
                    return popupContainer.current || document.body;
                  }}
                />
              </Flex>
              <Divider style={{ margin: "12px 0" }} />
              <SectionLink section={ReleaseSectionEnum.basicValidation}>
                Basic Validation
              </SectionLink>
              {isBuildSupportsSmokeTests(build) && (
                <SectionLink section={ReleaseSectionEnum.smokeTesting}>
                  Smoke Testing
                </SectionLink>
              )}
              <SectionLink section={ReleaseSectionEnum.buildArtifacts}>
                Build Artifacts
              </SectionLink>
              <SectionLink section={ReleaseSectionEnum.staticAnalysis}>
                Static Analysis
              </SectionLink>
              <SectionLink section={ReleaseSectionEnum.buildLogging}>
                Build Logging
              </SectionLink>
              <Divider style={{ margin: "12px 0" }} />
              <Flex
                css={{ padding: "8px 0 4px 16px", flexDirection: "column" }}
              >
                <ReleaseBuildPopup />
              </Flex>
            </Anchor>
          </Box>
        </Col>
        <Col span={18} style={{ padding: "24px 36px 24px 36px" }}>
          <BasicValidationSection
            dividerStyle={{ marginTop: 0, marginBottom: 16 }}
            section={ReleaseSectionEnum.basicValidation}
          />
          {isBuildSupportsSmokeTests(build) && (
            <SmokeTestingSection section={ReleaseSectionEnum.smokeTesting} />
          )}
          <BuildArtifactsSection section={ReleaseSectionEnum.buildArtifacts} />
          <StaticAnalysisSection section={ReleaseSectionEnum.staticAnalysis} />
          <BuildLoggingSection section={ReleaseSectionEnum.buildLogging} />
        </Col>
      </Row>
      <ReleaseTutorialModal />
    </ReleaseContext.Provider>
  );
};

const ReleaseBuildPopup: React.FC = () => {
  const { colors } = useTheme();
  const [modalVisible, setModalVisible] = useState(false);
  const {
    state,
    props: { build },
  } = useReleaseContext();
  const { isLatestRelease } = determineReleaseEligibility(
    build,
    useStore(selectedReleasedBuild)
  );

  const [radioValue, setRadioValue] = useState<"full" | "ip" | "platform">(
    "full"
  );

  const content = useContent();
  const checks: {
    section: ReleaseSectionEnum;
    data: StatusCheckContent<string>;
    state: ReleaseSectionState<string>;
  }[] = [
    {
      section: ReleaseSectionEnum.basicValidation,
      data: content.basicValidation,
      state: state.basicValidation,
    },
    ...(isBuildSupportsSmokeTests(build)
      ? [
          {
            section: ReleaseSectionEnum.smokeTesting,
            data: content.smokeTesting,
            state: state.smokeTesting,
          },
        ]
      : []),
    {
      section: ReleaseSectionEnum.buildArtifacts,
      data: content.buildArtifacts,
      state: state.buildArtifacts,
    },
    {
      section: ReleaseSectionEnum.staticAnalysis,
      data: content.staticAnalysis,
      state: state.staticAnalysis,
    },
    {
      section: ReleaseSectionEnum.buildLogging,
      data: content.buildLogging,
      state: state.buildLogging,
    },
  ];

  const isReleaseDisabled =
    isLatestRelease || state.basicValidation.status === "error"
      ? true
      : undefined;

  const releaseProps: ReleaseProps = {
    onCancel: () => setModalVisible(false),
    releaseButtonProps: {
      disabled: isReleaseDisabled || undefined,
      onComplete: () => setModalVisible(false),
      build,
    },
  };

  const history = useHistory();

  return (
    <>
      <Button
        type="primary"
        onClick={async () => {
          const hasSecurityToken = await getSecurityTokenDetails();
          if (hasSecurityToken || stage === "dev") {
            setModalVisible(true);
          } else {
            const secTokenConfirm = confirm({
              title: "Releasing now requires a security token",
              icon: <ExclamationCircleOutlined />,
              content:
                "Would you like to be redirected to the security token management page so that you can add a security token?",
              onOk() {
                secTokenConfirm.destroy();
                const currentPath = location.pathname; // This would be '/apps/2108257l5oobyoe/builds/241011y0shif45r/release'
                const newPath = currentPath.substring(
                  0,
                  currentPath.lastIndexOf("/")
                ); // This will remove '/release', needed to show the `goToManageSecurityToken` modal

                history.push(newPath);
                goToManageSecurityToken();
              },
              onCancel() {
                secTokenConfirm.destroy();
              },
            });
          }
        }}
      >
        Release build
      </Button>
      <Modal
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        bodyStyle={{ padding: 0 }}
        footer={null}
      >
        <Flex css={{ borderBottom: "1px solid #D9D9D9" }}>
          {isLatestRelease ? (
            <ReleaseSummary status="success">
              <Title level={5} style={{ margin: 0 }}>
                Build currently released
              </Title>
              <Text>This build is the current latest release</Text>
            </ReleaseSummary>
          ) : checks.every((check) => check.state.status === "success") ? (
            <ReleaseSummary status="success">
              <Title level={5} style={{ margin: 0 }}>
                Build can be safely released
              </Title>
              <Text>All of the release validation checks have succeeded</Text>
            </ReleaseSummary>
          ) : checks.some((check) => check.state.status === "error") ? (
            <ReleaseSummary status="error">
              <Title level={5} style={{ margin: 0 }}>
                Build cannot be safely released
              </Title>
              <Text style={{ fontSize: "13px" }}>
                At least one of the release validation checks have failed
              </Text>
            </ReleaseSummary>
          ) : checks.some((check) => check.state.status === "warning") ? (
            <ReleaseSummary status="warning">
              <Title level={5} style={{ margin: 0 }}>
                Build can be released with caution
              </Title>
              <Text style={{ fontSize: "13px" }}>
                At least one of the release validation checks has a warning
                state
              </Text>
            </ReleaseSummary>
          ) : (
            <ReleaseSummary status="progress">
              <Title level={5} style={{ margin: 0 }}>
                Build can be released with caution
              </Title>
              <Text style={{ fontSize: "13px" }}>
                At least one of the release validation checks is still in
                progress
              </Text>
            </ReleaseSummary>
          )}
        </Flex>
        <Collapse
          accordion
          bordered={false}
          expandIconPosition="right"
          expandIcon={() => {
            return <Text style={{ color: colors.primary }}>Details</Text>;
          }}
        >
          {checks.map(({ section, data, state: { checks, status } }) => {
            const summary = Object.entries(data.content).map(([key, value]) => {
              const status = checks[key];
              return { key, status, message: value[status] };
            });

            return (
              <Collapse.Panel
                key={section}
                className={section}
                header={
                  <Flex css={{ alignItems: "center", gap: "8px" }}>
                    <StatusIcon status={status} />
                    <Box>{data.title[status]}</Box>
                  </Flex>
                }
              >
                {summary.map(({ key, status, message }) => {
                  return (
                    <Flex key={key} css={{ alignItems: "center", gap: "8px" }}>
                      <StatusCheckIcon status={status} />
                      <Text style={{ margin: 0 }}>{message}</Text>
                    </Flex>
                  );
                })}
              </Collapse.Panel>
            );
          })}
        </Collapse>
        <Flex
          css={{
            padding: "24px 24px 0 24px",
            flexDirection: "column",
            gap: "8px",
            borderTop: "1px solid #D9D9D9",
          }}
        >
          <Text>Configure release</Text>
          <Radio.Group
            value={radioValue}
            onChange={(e) => setRadioValue(e.target.value)}
          >
            <Radio.Button value="full">Full release</Radio.Button>
            <Radio.Button value="ip">IP address release</Radio.Button>
            <Radio.Button value="platform">Platform release</Radio.Button>
          </Radio.Group>
        </Flex>
        {radioValue === "ip" ? (
          <IPRelease {...releaseProps} />
        ) : radioValue === "platform" ? (
          <PlatformRelease {...releaseProps} />
        ) : (
          <FullRelease {...releaseProps} />
        )}
      </Modal>
    </>
  );
};

const ReleaseSummary: React.FC<{ status: Status }> = ({ children, status }) => {
  return (
    <Flex css={{ padding: "16px", gap: "8px", alignItems: "start" }}>
      <Flex css={{ marginTop: "6px" }}>
        <StatusIconFilled status={status} />
      </Flex>
      <Flex css={{ flexDirection: "column" }}>{children}</Flex>
    </Flex>
  );
};

const IPRelease: React.FC<ReleaseProps> = ({
  releaseButtonProps,
  onCancel,
}) => {
  const {
    props: { build, app },
  } = useReleaseContext();
  const [validationError, setValidationError] = useState("");

  const releasedBuildId = useStore((state) => state.buildState.releasedBuildId);
  const [ipAddresses, setIPAddresses] = useState(() => {
    const redirections = (app.meta?.releaseRedirections || []).filter(
      (r) => r.rule === "buildByIp"
    ) as Extract<ReleaseRedirectionRule, { rule: "buildByIp" }>[];

    const redirection =
      redirections.find((r) => r.buildId === releasedBuildId) ||
      redirections.find(Boolean);

    return redirection?.ipList.join(", ") || "";
  });

  const [onIPAddressesValidation] = useDebouncedCallback(
    async (value: string) => {
      try {
        await ipAddressesValidation.validate(value);
        setValidationError("");
      } catch (err) {
        setValidationError(err.message);
      }
    },
    200
  );
  useEffect(() => {
    onIPAddressesValidation(ipAddresses);
  }, [ipAddresses]);

  const isReleaseDisabled =
    !ipAddresses ||
    releaseButtonProps.disabled ||
    !!validationError ||
    undefined;

  return (
    <Flex css={{ flexDirection: "column", padding: "16px 24px" }}>
      <Space direction="vertical">
        <Text>
          Release your build across a comma-separated list of IP addresses.
        </Text>
        <Input.TextArea
          value={ipAddresses}
          onChange={(e) => setIPAddresses(e.target.value)}
          rows={4}
          placeholder="212.220.134.88, 213.215.252.129..."
        />
        <Box css={{ height: "24px" }}>
          {validationError && <Text type="danger">{validationError}</Text>}
        </Box>
      </Space>

      <ReleaseActions
        onCancel={onCancel}
        releaseButtonProps={{
          ...releaseButtonProps,
          disabled: isReleaseDisabled,
          partialReleaseOptions: {
            redirection: {
              rule: "buildByIp",
              buildId: build.id,
              ipList: ipAddresses.split(",").map((address) => address.trim()),
            },
          },
        }}
      >
        Partially release build
      </ReleaseActions>
    </Flex>
  );
};

interface ReleaseProps {
  releaseButtonProps: ReleaseBuildButtonProps;
  onCancel: () => void;
}

const PlatformRelease: React.FC<ReleaseProps> = ({
  releaseButtonProps,
  onCancel,
}) => {
  const {
    props: { build, app },
  } = useReleaseContext();

  const releasedBuildId = useStore((state) => state.buildState.releasedBuildId);
  const [platforms, setPlatforms] = useState(() => {
    const redirections = (app.meta?.releaseRedirections || []).filter(
      (r) => r.rule === "buildByPlatform"
    ) as Extract<ReleaseRedirectionRule, { rule: "buildByPlatform" }>[];

    const redirection =
      redirections.find((r) => r.buildId === releasedBuildId) ||
      redirections.find(Boolean);

    return redirection?.platforms || [];
  });

  return (
    <Flex css={{ flexDirection: "column", padding: "16px 24px", gap: "16px" }}>
      <Space direction="vertical">
        <Text>Release your build across specific platforms.</Text>
        <Checkbox.Group
          options={[
            { label: "Mac", value: PlatformName.mac },
            { label: "Windows", value: PlatformName.windows },
            { label: "Linux", value: PlatformName.linux },
          ]}
          value={platforms}
          onChange={(values: PlatformName[]) => setPlatforms(values)}
        />
      </Space>

      <ReleaseActions
        onCancel={onCancel}
        releaseButtonProps={{
          ...releaseButtonProps,
          disabled:
            !platforms.length || releaseButtonProps.disabled || undefined,
          partialReleaseOptions: {
            redirection: {
              rule: "buildByPlatform",
              buildId: build.id,
              platforms,
            },
          },
        }}
      >
        Partially release build
      </ReleaseActions>
    </Flex>
  );
};

const FullRelease: React.FC<ReleaseProps> = (props) => {
  const {
    props: { app },
  } = useReleaseContext();

  const current = props.releaseButtonProps.build;

  const builds = useStore((state) => state.buildState.builds);
  const getConflictingBuild = () => {
    if (!current.appVersion) return null;

    for (const r of app.meta?.releaseRedirections || []) {
      if (r.rule !== "buildByIp" && r.rule !== "buildByPlatform") continue;

      const build = builds[r.buildId];
      if (build && build.id === current.id) continue;

      // partially released builds with a greater/equal version can be problematic
      // as they won't be auto-updated if the a full release is done on a lesser/equal version
      const buildVersion = build.appVersion;
      if (buildVersion && semver.gte(buildVersion, current.appVersion)) {
        return build;
      }
    }
  };

  const conflictingBuild = getConflictingBuild();

  return (
    <Flex css={{ flexDirection: "column", padding: "16px 24px", gap: "16px" }}>
      {conflictingBuild && (
        <Alert
          type="info"
          message={
            <Text>
              A build version (
              <Link to={`/apps/${app.id}/builds/${conflictingBuild.id}`}>
                {conflictingBuild.appVersion}
              </Link>
              ) greater than or equal to the current version (
              {current.appVersion}) has previously been partially released.
              Users who have updated to that version won't automatically update
              to the current one
            </Text>
          }
        />
      )}
      <Text>Release your build across all platforms.</Text>
      <ReleaseActions {...props}>Release build</ReleaseActions>
    </Flex>
  );
};

const ReleaseActions: React.FC<ReleaseProps> = ({
  onCancel,
  releaseButtonProps,
  children,
}) => {
  return (
    <Flex css={{ justifyContent: "space-between", alignItems: "center" }}>
      <Box>
        <BuildReleaseTag build={releaseButtonProps.build} />
      </Box>
      <Flex css={{ gap: "8px" }}>
        <Button onClick={onCancel}>Cancel</Button>
        <ReleaseBuildButton {...releaseButtonProps}>
          {children}
        </ReleaseBuildButton>
      </Flex>
    </Flex>
  );
};

export const SelectRelease: React.FC<SelectProps> = (props) => {
  const {
    state,
    props: { build },
    setState,
  } = useReleaseContext();

  const builds = useStore((state) => state.buildState.builds);
  const releasedBuilds = getSortedReleasedBuilds(builds).filter(
    (current) => current.id !== build.id
  );

  if (releasedBuilds.length < 1) return null;

  return (
    <Select
      value={state.releaseId}
      onChange={(releaseId) => setState((prev) => ({ ...prev, releaseId }))}
      placeholder="Compare"
      style={{ width: "100%" }}
      options={releasedBuilds.map((build) => {
        return {
          value: build.id,
          label: (
            <Flex css={{ alignItems: "center", gap: "8px" }}>
              <img src={build.icon} width={20} height={20} />
              <Text>Compare {build.appVersion}</Text>
            </Flex>
          ),
        };
      })}
      {...props}
    />
  );
};
