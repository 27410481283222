// libs
import React, { useState, useEffect } from "react";
import ReactTimeAgo from "react-time-ago";

// components
import { MacCertificate } from "./MacCertificate";
import { WindowsCertificate } from "./WindowsCertificate";
import { Modal, Dropdown, Button, Tabs, Alert, message } from "antd";
import {
  DeleteOutlined,
  SettingOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Heading } from "../../atoms/Heading";
import { Box, Flex, Anchor } from "../../atoms/Primitives";
import certPlaceholder from "../../../~reusables/images/certificate.png";

// utils
import {
  CertPlatform,
  CertTabs,
  IHSMCertificate,
} from "../../../~reusables/types";
import {
  useStore,
  selectedApp,
  $macCertExists,
  $windowsCertExists,
  $linuxCertExists,
} from "../../../store";
import { track } from "../../../~reusables/util/analytics";
import {
  beginReplacingWindowsCert,
  deleteLinuxPgpKey,
  deleteMacCertificate,
  deleteWindowsCertificate,
} from "../../../~reusables/actions";
import { handleChatClick } from "../../build/ChoosePackage";
import { selectedReleasedBuild } from "../../../~reusables/actions/builds";
import { ObjectInterpolation } from "@emotion/core";
import { MacTarget, WindowsTarget } from "@todesktop/shared";

export const CertificateModal: React.FC<{
  showModal: boolean;
  setShowModal: (value: React.SetStateAction<boolean>) => void;
  platform: CertPlatform;
}> = ({ platform, showModal, setShowModal }) => (
  <Modal
    open={showModal}
    onCancel={() => setShowModal(false)}
    closable
    centered
    footer={null}
  >
    <Heading textAlign="center" pb={5} variant="h3" as="h3">
      Manage your {platform} Certificate
    </Heading>
    {platform === CertPlatform.Mac ? (
      <MacCertificate />
    ) : (
      <WindowsCertificate />
    )}
  </Modal>
);

export const CertificateDropdown: React.FC<
  | {
      platform: CertPlatform.Mac;
      options: { target: MacTarget; label: string }[];
    }
  | {
      platform: CertPlatform.Windows;
      options: { target: WindowsTarget; label: string }[];
    }
  | {
      platform: CertPlatform.Linux;
      options: { target: "linux"; label: string }[];
    }
> = ({ platform, options }) => {
  const app = useStore(selectedApp);
  const macCertExists = useStore($macCertExists);
  const windowsCertExists = useStore($windowsCertExists);
  const linuxCertExists = useStore($linuxCertExists);
  const items = options.map(({ target, label }) => {
    const hasCertificate =
      platform === CertPlatform.Windows
        ? Boolean(
            app?.windowsHsmCertNames?.[target] ||
              (target === "windows" && windowsCertExists)
          )
        : platform === CertPlatform.Mac
        ? Boolean(
            app?.macHsmCertNames?.[target] ||
              (target === "mac" && macCertExists)
          )
        : platform === CertPlatform.Linux
        ? target === "linux" && linuxCertExists
        : false;

    return { target, label, hasCertificate };
  });

  return items.some((item) => item.hasCertificate) ? (
    <Dropdown
      menu={{
        items: items.map(({ target, label, hasCertificate }) => {
          return {
            key: target,
            label: `Delete ${label}`,
            icon: <DeleteOutlined />,
            disabled: !hasCertificate,
            onClick: () => {
              Modal.confirm({
                title: "Are you sure you want to delete this certificate?",
                content: "This action can not be undone.",
                async onOk() {
                  track({
                    event: "Update Certificate",
                    properties: {
                      action: "delete",
                      platform: platform,
                    },
                  });
                  try {
                    if (platform === CertPlatform.Windows) {
                      await deleteWindowsCertificate(app, target);
                    } else if (platform === CertPlatform.Mac) {
                      await deleteMacCertificate(app, target);
                      message.success(`Deleted ${label}`);
                    } else if (platform === CertPlatform.Linux) {
                      await deleteLinuxPgpKey(app);
                      message.success(`Deleted ${label}`);
                    }
                  } catch (error) {
                    Modal.error({
                      title: "Error deleting certificate",
                      content: error.message,
                    });
                  }
                },
                okText: "Yes, Delete",
                okType: "danger",
                cancelText: "No",
              });
            },
          };
        }),
      }}
      trigger={["click"]}
    >
      <Button>
        <SettingOutlined /> <DownOutlined />
      </Button>
    </Dropdown>
  ) : null;
};

export const TablessCertPane: React.FC<{
  viewComponentProps: {
    cert: IHSMCertificate;
    target?: MacTarget | "windows";
    extra?: JSX.Element;
    legacy?: boolean;
  };
  addComponent: JSX.Element;
  notarizationComponent?: JSX.Element;
  tabUtils: {
    certTab: CertTabs;
    setEditCertMode?: any;
    setCertTab: any;
    isCertInUse: boolean;
  };
  style?: ObjectInterpolation<any>;
}> = ({
  addComponent,
  notarizationComponent,
  viewComponentProps: { cert, target, extra, legacy },
  tabUtils: { certTab, setEditCertMode, setCertTab, isCertInUse },
  style = {},
}) => {
  return (
    <>
      <Tabs
        activeKey={certTab}
        tabBarStyle={{ display: "none" }}
        items={[
          {
            key: CertTabs.ViewCert,
            label: "View cert",
            children: (
              <Flex
                css={{
                  flexDirection: "row",
                  gap: "8px",
                  width: "100%",
                  ...style,
                }}
              >
                <Box maxWidth={120}>
                  <img width="100%" src={certPlaceholder} />
                </Box>
                <Flex
                  css={{ flexDirection: "column", gap: "8px", width: "100%" }}
                >
                  <Box>
                    <Heading style={{ marginBottom: 10 }} variant="h5" as="h5">
                      {cert?.subject || cert?.name}
                      {cert &&
                        cert.subjectAlternativeName &&
                        ` (${cert.subjectAlternativeName})`}
                    </Heading>
                    {cert?.properties?.expiresOn && (
                      <>
                        {new Date(cert.properties.expiresOn) > new Date()
                          ? "Expires"
                          : "Expired"}{" "}
                        <b>
                          {cert.properties.expiresOn && (
                            <ReactTimeAgo
                              date={new Date(cert.properties.expiresOn)}
                            />
                          )}
                        </b>
                        .
                      </>
                    )}
                  </Box>
                  <Box>
                    {cert?.issuer && (
                      <>
                        Issued by <b>{cert.issuer}</b>.
                      </>
                    )}
                    {extra}
                  </Box>
                  <Flex
                    css={{ alignItems: "center", gap: "8px", flexWrap: "wrap" }}
                  >
                    <Button
                      onClick={() => {
                        if (target === "windows") {
                          beginReplacingWindowsCert();
                        }
                        setCertTab(CertTabs.AddCert);
                        if (setEditCertMode) setEditCertMode(true);
                      }}
                    >
                      Replace Certificate
                    </Button>
                    {target === "mac" && (
                      <Button
                        onClick={() => {
                          setCertTab(CertTabs.AddNotarization);
                          if (setEditCertMode) setEditCertMode(true);
                        }}
                      >
                        Edit Notarization Credentials
                      </Button>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            ),
          },
          {
            key: CertTabs.AddCert,
            label: "Add Cert",
            children: (
              <>
                {isCertInUse && (
                  <a
                    onClick={() => {
                      setCertTab(CertTabs.ViewCert);
                      if (setEditCertMode) setEditCertMode(false);
                    }}
                  >
                    &laquo; View certificate
                  </a>
                )}
                {addComponent}
              </>
            ),
          },
          {
            key: CertTabs.AddNotarization,
            label: "Add Notarization",
            children: (
              <>
                {isCertInUse && (
                  <a
                    onClick={() => {
                      setCertTab(CertTabs.ViewCert);
                      if (setEditCertMode) setEditCertMode(false);
                    }}
                  >
                    &laquo; View certificate
                  </a>
                )}
                {notarizationComponent}
              </>
            ),
          },
        ]}
      ></Tabs>
    </>
  );
};

export const CertificateChangeWarning: React.FC = () => {
  const releasedBuild = useStore(selectedReleasedBuild);
  if (!releasedBuild) return null;

  return (
    <Alert
      type="warning"
      closable={true}
      message={
        <>
          Be careful when changing your Mac Certificate. If you have already
          released a build, then make sure that the new certificate is created
          under the same organization or Apple ID as the old certificate. Please{" "}
          <Anchor onClick={(e) => handleChatClick(e)}>contact us</Anchor> if you
          are unsure on how to proceed.
        </>
      }
    />
  );
};

export const useCertTab = (
  isCertInUse: boolean,
  resetForm?: any
): [CertTabs, React.Dispatch<React.SetStateAction<CertTabs>>] => {
  const [certTab, setCertTab] = useState(
    isCertInUse ? CertTabs.ViewCert : CertTabs.AddCert
  );

  useEffect(() => {
    if (resetForm) resetForm();
    if (isCertInUse) {
      setCertTab(CertTabs.ViewCert);
    } else {
      setCertTab(CertTabs.AddCert);
    }
  }, [isCertInUse]);

  return [certTab, setCertTab];
};

export const genFormEvent = (name = "", value = "") => ({
  target: { name, value },
});
