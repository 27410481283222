import {
  IApp,
  LinuxArtifactName,
  MacArtifactName,
  WindowsArtifactName,
  Arch,
  MacArch,
  Build,
} from "@todesktop/shared";
import xxtea from "./xxtea.js";
import {
  AppleFilled,
  WindowsFilled,
  QqOutlined,
  DesktopOutlined,
} from "@ant-design/icons";

const stage =
  process.env.NODE_ENV === "development"
    ? "dev"
    : process.env.NODE_ENV === "production"
    ? "prod"
    : process.env.NODE_ENV;

const awsLambdaBase = `https://sls-${stage}.todesktop.com`;

interface IDownloadMeta {
  wasArtifactCreated: boolean;
  artifactName?: LinuxArtifactName | MacArtifactName | WindowsArtifactName;
  arch?: Arch | MacArch;
  platform: "mac" | "windows" | "linux" | "universal";
  releasedBuild: Build;
  app: IApp;
  label: string;
}

export const getDownloadMeta = (downloadsInfo: IDownloadMeta) => {
  const shouldShow = shouldShowDownloadUrl(downloadsInfo);
  const { app, arch, artifactName, platform, label } = downloadsInfo;
  const downloadUrl = getDownloadUrl({
    appId: app.id,
    platform,
    arch,
    artifactName,
    domain: app.customDomain,
  });

  let icon: any;
  if (platform === "mac") {
    icon = AppleFilled;
  } else if (platform === "windows") {
    icon = WindowsFilled;
  } else if (platform === "linux") {
    icon = QqOutlined;
  } else {
    icon = DesktopOutlined;
  }

  return { shouldShow, downloadUrl, icon, label };
};

export interface IDownloadUrl {
  appId: IApp["id"];
  arch?: Arch | MacArch;
  artifactName?: LinuxArtifactName | MacArtifactName | WindowsArtifactName;
  buildId?: string;
  domain?: string;
  platform: "linux" | "mac" | "windows" | "universal";
}

export const getLinuxPgpMetadataUrl = (appId: IApp["id"]) => {
  return `https://dl.todesktop.com/${appId}/apt/key-metadata.json`;
};

export const getDownloadUrl = ({
  appId,
  arch = "x64",
  artifactName,
  buildId,
  domain,
  platform = "universal",
}: IDownloadUrl) => {
  let rootUrl: string;

  if (!domain) {
    rootUrl = "https://dl.todesktop.com";
  } else if (domain.startsWith("https://")) {
    rootUrl = domain;
  } else {
    rootUrl = "https://" + domain;
  }

  let result = rootUrl.replace(/\/+$/, "");
  result += domain ? "" : `/${appId}`;

  // This is the download for a particular build
  if (buildId) {
    result += `/builds/${buildId}`;
  }

  if (platform !== "universal") {
    result += `/${platform}`;

    if (artifactName) {
      result += `/${artifactName}/${arch}`;
    }
  }

  return result;
};

export const shouldShowDownloadUrl = ({
  wasArtifactCreated,
  artifactName,
  arch,
  platform,
  releasedBuild,
  app,
}: IDownloadMeta) => {
  if (!wasArtifactCreated) {
    return false;
  }

  if (app.appType !== "electron" || platform === "universal") {
    return true;
  }

  if (!releasedBuild) {
    return false;
  }

  const exists =
    releasedBuild[platform] &&
    releasedBuild[platform].artifactDownloads &&
    releasedBuild[platform].artifactDownloads[artifactName] &&
    releasedBuild[platform].artifactDownloads[artifactName][arch];

  return !!exists;
};

export const takeScreenshot = (
  url: string,
  height: number,
  width: number,
  deviceScaleFactor: number,
  appId: IApp["id"]
) => {
  return `${awsLambdaBase}/screenshot/take-screenshot?screenshotId=${encodeURIComponent(
    xxtea.encryptToBase64(url, appId)
  )}&height=${height}&width=${width}&deviceScaleFactor=${deviceScaleFactor}&appId=${appId}`;
};
