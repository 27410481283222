// libs
import React, { useEffect, useState } from "react";

// components
import { CheckCircleFilled, WarningFilled } from "@ant-design/icons";
import { Button, Form, Skeleton, Typography, message } from "antd";
import { FormInput, FormItem } from "../../atoms/FormUtils";
import { Flex } from "../../atoms/Primitives";
import { Space } from "../../atoms/Space";
import { MacNotarizationForm } from "./MacNotarizationForm";
import {
  CertificateChangeWarning,
  TablessCertPane,
  genFormEvent,
  useCertTab,
} from "./SharedCertUtils";

// utils
import Upload, { UploadChangeParam } from "antd/lib/upload";
import { $macCertExists, selectedApp, useStore } from "../../../store";
import {
  isFileChangeError,
  toBase64,
  updateMacCert,
} from "../../../~reusables/actions";
import { hrefLinks } from "../../../~reusables/constants";
import { callFirebaseFunction } from "../../../~reusables/firebase";
import useForm from "../../../~reusables/hooks/useForm";
import {
  CertPlatform,
  CertTabs,
  IHSMCertificate,
} from "../../../~reusables/types";
import { track } from "../../../~reusables/util/analytics";
import { certificateValidation } from "../../../~reusables/util/validationRules";

const { Text } = Typography;

const getHSMCert = callFirebaseFunction("getHSMCert");

export const MacCertificate: React.FC<{ after?: React.ReactNode }> = ({
  after,
}) => {
  const [hsmCertDetails, setHSMCertDetails] = useState<IHSMCertificate>();
  const [isCertLoading, setCertLoading] = useState(true);
  const [certUploadError, setCertUploadError] = useState<string>();

  const isCertInUse = useStore($macCertExists);

  const app = useStore(selectedApp);
  const { customMacCodeSign, id, customNotarization, macHsmCertNames } = app;

  useEffect(() => {
    if (customMacCodeSign?.type === "hsm" || macHsmCertNames?.mac) {
      getHSMCert({ type: "mac", appId: id, hsmCertName: macHsmCertNames?.mac })
        .then((res) => {
          setHSMCertDetails(res.data);
        })
        .catch((err) => message.error(err.message))
        .finally(() => setCertLoading(false));
    } else {
      setHSMCertDetails(null);
      setCertLoading(false);
    }
  }, [customMacCodeSign?.type, macHsmCertNames?.mac, id]);

  const hasPreviouslyEnteredCustomNotarization =
    customNotarization?.type === "hsm" ||
    customNotarization?.type === "hsm-api";

  type CertFormData = {
    password: string;
    pfxBase64: string;
  };
  const certFormData: CertFormData = {
    password: "",
    pfxBase64: "",
  };

  const callbackCert = async (macFormData: CertFormData) => {
    track({
      event: "Update Certificate",
      properties: {
        type: "certificate",
        action: "save",
        platform: CertPlatform.Mac,
      },
    });

    const { pfxBase64, password } = macFormData;

    try {
      await updateMacCert(app, password, pfxBase64, "mac");
      message.success("Mac Certificate saved");
      if (hasPreviouslyEnteredCustomNotarization) {
        setCertTab(CertTabs.ViewCert);
      } else {
        setCertTab(CertTabs.AddNotarization);
      }
    } catch (err) {
      console.log(err);
      setCertUploadError(err.message);
      message.error(err.message);
    }
  };

  const certForm = useForm(certFormData, callbackCert, certificateValidation);

  const [certTab, setCertTab] = useCertTab(isCertInUse, certForm.resetForm);

  useEffect(() => {
    if (certForm.formError) {
      message.error(certForm.formError);
    }
  }, [certForm.formError]);

  const certChange = ({ file }: UploadChangeParam) => {
    console.log({});
    if (isFileChangeError(file)) {
      return;
    }

    if (file) {
      toBase64((file as unknown) as Blob)
        .then((base64) => {
          certForm.handleChange(genFormEvent("pfxBase64", base64));
        })
        .catch((err) => message.error(err.message, 3));
    }
  };
  if (isCertLoading) {
    return <Skeleton />;
  }

  return (
    <>
      <Flex css={{ flexDirection: "column", gap: "8px" }}>
        <Text style={{ fontWeight: 500 }}>
          Developer ID Application certificate
        </Text>
        <TablessCertPane
          tabUtils={{ certTab, setCertTab, isCertInUse }}
          viewComponentProps={{
            cert:
              customMacCodeSign?.type === "hsm" || macHsmCertNames?.mac
                ? hsmCertDetails
                : { name: customMacCodeSign?.certName ?? "" },
            legacy: customMacCodeSign?.type !== "hsm",
            target: "mac",
            extra: hasPreviouslyEnteredCustomNotarization ? (
              customNotarization.type === "hsm" ? (
                <Text style={{ display: "block" }}>
                  Using Password-based Notarization
                  <CheckCircleFilled
                    style={{ color: "green", marginLeft: 5 }}
                  />
                </Text>
              ) : (
                <Text style={{ display: "block" }}>
                  Using App Store API Key Notarization
                  <CheckCircleFilled
                    style={{ color: "green", marginLeft: 5 }}
                  />
                </Text>
              )
            ) : (
              <Text style={{ display: "block" }}>
                <WarningFilled style={{ color: "#c00", marginRight: 5 }} />
                No Notarization Credentials Found
              </Text>
            ),
          }}
          notarizationComponent={
            <MacNotarizationForm
              onNotarizationUpdate={() => setCertTab(CertTabs.ViewCert)}
            />
          }
          addComponent={
            <Form layout="vertical" onFinish={certForm.handleSubmit}>
              <Space
                width="100%"
                size="md"
                direction="column"
                align="flex-start"
              >
                <Text color="support">
                  Mac certificates are required when releasing to production.
                  Learn more about buying a certificate{" "}
                  <a
                    href={hrefLinks.macCertSetup}
                    target="_blank"
                    rel="noreferrer"
                  >
                    here.
                  </a>
                </Text>
                {isCertInUse && <CertificateChangeWarning />}
                <FormItem
                  label={
                    <>
                      Certificate File (
                      <i>
                        <code>p12</code> or <code>pfx</code>
                      </i>
                      )
                    </>
                  }
                  error={certUploadError || certForm.errors.pfxBase64}
                >
                  <Upload
                    onChange={certChange}
                    accept=".p12,.pfx"
                    maxCount={1}
                    beforeUpload={() => false}
                  >
                    <Button>Select File</Button>
                  </Upload>
                </FormItem>
                <FormInput
                  error={certForm.errors.password}
                  label="Certificate Password"
                  name="password"
                  onChange={certForm.handleChange}
                  value={certForm.values.password}
                  disabled={certForm.isSubmitting}
                  password
                />

                <Flex justifyContent="center">
                  <Button
                    htmlType="submit"
                    loading={certForm.isSubmitting}
                    size="large"
                    type="primary"
                  >
                    {hasPreviouslyEnteredCustomNotarization
                      ? "Save changes"
                      : "Next"}
                  </Button>
                </Flex>
              </Space>
            </Form>
          }
        />
      </Flex>
      {after}
    </>
  );
};
